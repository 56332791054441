import React from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import GenericBackground from "./components/generic-background";

const PrivacyPolicy = () => {
  const navigate = useNavigate(); // Hook to handle navigation

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <GenericBackground>
      <div style={{ padding: "20px", lineHeight: "1.6", fontSize: "16px" }}>
        <button 
          onClick={handleBack} 
          style={{ 
            marginBottom: "20px", 
            padding: "0", 
            backgroundColor: "transparent", 
            color: "#727272", 
            border: "none", 
            borderRadius: "5px", 
            cursor: "pointer",
            textDecoration: "underline"
          }}
        >
          {"< Back"}
        </button>
        <h1 style={{ marginBottom: "20px" }}>Privacy Policy</h1>
        <p style={{ marginBottom: "30px" }}>Effective Date: December 24, 2024</p>

        <h2 style={{ marginBottom: "15px" }}>1. Introduction</h2>
        <p style={{ marginBottom: "20px" }}>
          This Privacy Policy describes how we collect, use, and share your personal data when you use our platform, <strong>gls-live.com</strong>, managed by Launch Pharm.
        </p>

        <h2 style={{ marginBottom: "15px" }}>2. Definitions</h2>
        <p style={{ marginBottom: "20px" }}>
          <strong>Personal Data:</strong> Any information relating to an identified or identifiable natural person.<br />
          <strong>Processing:</strong> Any operation performed on personal data, such as collection, storage, use, or deletion.<br />
          <strong>Data Controller:</strong> The entity that determines the purposes and means of processing personal data.<br />
          <strong>Data Processor:</strong> An entity that processes data on behalf of the data controller.
        </p>

        <h2 style={{ marginBottom: "15px" }}>3. Personal Data We Collect</h2>
        <ul style={{ marginBottom: "20px" }}>
          <li><strong>Data You Provide:</strong> First Name, Last Name, Email, Password, Organization, Company, Job Title, Therapeutic Area, Function, Country, Language Preference.</li>
          <li><strong>Data We Collect Automatically:</strong> Usage data tracked by Mixpanel and error logs from Sentry.</li>
        </ul>

        <h2 style={{ marginBottom: "15px" }}>4. How We Use Your Data</h2>
        <ul style={{ marginBottom: "20px" }}>
          <li><strong>Consent:</strong> For optional cookies and analytics tracking.</li>
          <li><strong>Contractual Necessity:</strong> To provide and manage user accounts and services.</li>
          <li><strong>Legitimate Interests:</strong> For platform improvements and debugging.</li>
        </ul>

        <h2 style={{ marginBottom: "15px" }}>5. Cookies and Tracking</h2>
        <p style={{ marginBottom: "20px" }}>
          We use cookies for session management and analytics. By using this platform, you consent to the use of cookies as described in our <a href="/cookie-policy">Cookie Policy</a>.
        </p>

        <h2 style={{ marginBottom: "15px" }}>6. Data Sharing and Transfers</h2>
        <ul style={{ marginBottom: "20px" }}>
          <li>Third-party services: Mixpanel, Sentry, Mailgun.</li>
          <li>Data Transfers: Data may be processed outside the EU, with safeguards such as Standard Contractual Clauses (SCCs).</li>
        </ul>

        <h2 style={{ marginBottom: "15px" }}>7. User Rights</h2>
        <ul style={{ marginBottom: "20px" }}>
          <li>Access, Rectification, Deletion, Portability, Objection to Processing.</li>
          <li>Exercise your rights by emailing <a href="mailto:support@gls-live.com">support@gls-live.com</a>.</li>
        </ul>

        <h2 style={{ marginBottom: "15px" }}>8. Data Retention</h2>
        <p style={{ marginBottom: "20px" }}>
          Personal data is retained until accounts are deleted. Upon request, we will securely erase your data.
        </p>

        <h2 style={{ marginBottom: "15px" }}>9. Data Security</h2>
        <ul style={{ marginBottom: "20px" }}>
          <li>Encryption: TLS for data in transit, AES-256 for data at rest.</li>
          <li>Regular backups and access controls to protect your data.</li>
          <li>Technical Measures: Firewalls, anti-malware software, and intrusion detection systems.</li>
          <li>Organizational Measures: Annual data protection training for all employees.</li>
        </ul>

        <h2 style={{ marginBottom: "15px" }}>10. Data Breach Notification</h2>
        <p style={{ marginBottom: "20px" }}>
          In the event of a data breach, affected users will be notified within 72 hours of detection if there is a high risk to their rights and freedoms.
        </p>

        <h2 style={{ marginBottom: "15px" }}>11. International Data Transfers</h2>
        <p style={{ marginBottom: "20px" }}>
          Your data may be transferred to countries outside the EU/EEA. We use appropriate safeguards, such as SCCs, to ensure your data is protected.
        </p>

        <h2 style={{ marginBottom: "15px" }}>12. Contact Information</h2>
        <p style={{ marginBottom: "20px" }}>
          Data Protection Officer (DPO): Christian Vismara (<a href="mailto:cv@launchpharm.com">cv@launchpharm.com</a>)<br />
          General Privacy Inquiries: <a href="mailto:support@gls-live.com">support@gls-live.com</a>
        </p>

        <h2 style={{ marginBottom: "15px" }}>13. Updates to This Policy</h2>
        <p>
          We may update this Privacy Policy periodically. Changes will be communicated on this page.
        </p>
      </div>
    </GenericBackground>
  );
};

export default PrivacyPolicy;
