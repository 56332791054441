import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { SquareLoader } from "react-spinners";
import styled from "styled-components";
import { setQueryParams } from "./store/company/actions";
import useQuery from "./utils/hooks/use-query";
import { initializeSentry, initializeTracking } from "./utils/tracking";
import { AppConfigProvider } from "./contexts/app-config/app-config-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Head from "./components/head";
import CookieConsent from "./components/privacy-policy/CookieConsent";

let startTracking = true
const hasConsent = localStorage.getItem('gls_cookie_consent');
if (hasConsent) {
  const consentData = JSON.parse(hasConsent);
  window._disableAnalytics = !consentData.analytics;
  startTracking = !window._disableAnalytics
}

if(startTracking) {
  initializeTracking();
  initializeSentry();
}


const queryClient = new QueryClient();

const App = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  let source = query.get("source") ?? "direct";

  useEffect(() => {
    dispatch(setQueryParams(source));
  }, [source, dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppConfigProvider>
        <Head />
        <Outlet />
        <CookieConsent />
      </AppConfigProvider>
    </QueryClientProvider>
  );
};

const BounceSpinner = ({ className }) => {
  return (
    <div className={className}>
      <SquareLoader
        loading={true}
        size={32}
        color="#796e65"
        speedMultiplier={2}
      />
    </div>
  );
};

const Spinner = styled(BounceSpinner)`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default App;
