import React, { useState, useEffect } from 'react';
import { disableMixpanel, disableSentry } from '../../utils/tracking';

import styled from 'styled-components';

const CONSENT_KEY = 'gls_cookie_consent';


const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem(CONSENT_KEY);
    if (!hasConsent) {
      setIsVisible(true);
      window._disableAnalytics = true;
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(CONSENT_KEY, JSON.stringify({
      analytics: true,
      functional: true,
      timestamp: new Date().toISOString()
    }));
    setIsVisible(false);
    window._disableAnalytics = false;
  };

  const handleReject = () => {
    localStorage.setItem(CONSENT_KEY, JSON.stringify({
      analytics: false,
      functional: true,
      timestamp: new Date().toISOString()
    }));
    setIsVisible(false);
    disableSentry();
    disableMixpanel();
    window._disableAnalytics = true;
  };

  if (!isVisible) return null;

  return (
    <ConsentWrapper>
      <ConsentContent>
        <TextContainer>
          <Text>
            We use cookies and similar technologies to provide essential platform functionality and improve your experience. By continuing, you consent to our use of:
            <DetailsButton onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? 'Show Less' : 'Show Details'}
          </DetailsButton>
          </Text>
          
          {showDetails && (
            <DetailsList>
              <li>Essential cookies for authentication and security</li>
              <li>Analytics cookies (Mixpanel) to understand platform usage</li>
              <li>Error monitoring (Sentry) to maintain platform stability</li>
              <li>Email delivery services (Mailgun) for notifications</li>
            </DetailsList>
          )}
          <ButtonContainer>
          <PolicyLink href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </PolicyLink>
          <RejectButton onClick={handleReject}>
            Reject Non-Essential
          </RejectButton>
          <AcceptButton onClick={handleAccept}>
            Accept All
          </AcceptButton>
          </ButtonContainer>
        </TextContainer>
        
      </ConsentContent>
    </ConsentWrapper>
  );
};

const ConsentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #e5e7eb;
  padding: 1rem;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

const ConsentContent = styled.div`
  max-width: 72rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  font-size: 0.875rem;
  color: #4b5563;
`;

const Text = styled.p`
  margin: 0;
  font-size: 0.875rem;
`;

const DetailsList = styled.ul`
  margin: 0.5rem 0 0 1.5rem;
  font-size: 0.875rem;
  list-style-type: disc;
  
  li {
    margin: 0.25rem 0;
  }
`;

const DetailsButton = styled.button`
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: transparent;
  border: 0;
  padding: 0;
  text-decoration: underline;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  width: 100%;
`;

const PolicyLink = styled.a`
  font-size: 0.875rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  text-decoration: underline;
  
  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

const BaseButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const RejectButton = styled(BaseButton)`
  background-color: transparent;
  border: none;
  
  &:hover {
    background-color: transparent;
    opacity: 0.8;
  }
`;

const AcceptButton = styled(BaseButton)`
  color: white;
  background-color: #727272;
  border: none;
  
  &:hover {
    background-color: #333333;
  }
`;

export default CookieConsent;