import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import LobbyGLS from "./LobbyGLS";
import { trackEvent } from "../../store/tracking/actions";
import { useAppConfig } from "../../contexts/app-config/app-config-context";
import breakpoint from "styled-components-breakpoint";
import Modal from "../../components/modal";
import MediaPlayer from "../../components/media-hub/media-player";
import { getMediaId } from "../../utils/media/media";

const GLS_VIDEO_TITLE = "";
const GLS_VIDEO_URL = "https://playout.3qsdn.com/9be13018-6a16-4602-a88a-055db38ece00";

const SharedLayoutGLS = () => {
  const dispatch = useDispatch();
  const { config } = useAppConfig();
  const [currentMedia, setCurrentMedia] = useState(null);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: "PAGE_VIEW",
        data: { pageName: "GLS_LOBBY" },
      })
    );
    setCurrentMedia({
      title: GLS_VIDEO_TITLE,
      url: GLS_VIDEO_URL,
      id: 1, // Assign an appropriate ID if needed
    });
  }, [dispatch]);

  const handleMediaClick = () => {
    setCurrentMedia({
      title: GLS_VIDEO_TITLE,
      url: GLS_VIDEO_URL,
      id: 1, // Assign an appropriate ID if needed
    });
  };

  return (
    <LobbyContainer>
      <LobbyBackground>
        <LobbyRow>
          <ItemParent>
            <LobbyBigSquare
              src="/images/gls/home/3_Post.jpg"
              className="img-fluid"
              onClick={handleMediaClick}
            />
          </ItemParent>
          <ItemParent />
        </LobbyRow>
        <LobbyRow>
          <LobbyGLS />
          <Outlet />
        </LobbyRow>
        <Divider />
      </LobbyBackground>
      <Footer>
        <LeftFooter>
          <img src={config?.footer_left_logo} alt="logo" />
        </LeftFooter>
        <RightFooter>
          <img src={config?.footer_right_logo} alt="logo" />
        </RightFooter>
      </Footer>
      <Modal
        footer={null}
        isOpen={!!currentMedia?.id}
        title={currentMedia?.title}
        closeModal={() => setCurrentMedia(null)}
      >
        <MediaPlayer mediaId={getMediaId(currentMedia?.url)} />
      </Modal>
    </LobbyContainer>
  );
};

const LobbyRow = styled.div`
  display: flex;
  gap: 0.8rem;
  margin: 0.5rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
`;

const LobbyContainer = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    margin-bottom: 80px;
    margin-top: 50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 118px;
  }
`;

const LobbyBackground = styled.div`
  max-width: 110vh;
  margin-bottom: 0 !important;
  height: 90vh;
  margin: 4rem;
  @media (max-width: 768px) {
      display: flex;
      flex: 1;
      margin: 1.5rem;
      flex-direction: column;
      height: auto;
      height: 100vh;
  }
  display: block;
  justify-content: center;
  img.img-fluid {
    max-width: 100%;
  }
`;

const ItemParent = styled.div`
  flex: 0.5;
  cursor: default;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const LobbyBigSquare = styled.img`
  max-height: 50vh;
  cursor: pointer; // Add cursor pointer to indicate clickability
`;

const Divider = styled.div`
  width: 100%;
  height: 15px;
  background: url("/images/gls/home/7_GLS24_PF_ray.jpg");
  margin: 0.5rem 0;
`;

const LeftFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4px;
  img {
    max-height: 34px;
  }
`;

const RightFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  img {
    max-height: 44px;
  }
`;

const Footer = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0.8rem 2.5rem 0.8rem 2.5rem;
  margin-right: auto;
  background-color: #fff;
  margin-left: auto;
  border-top: 2px solid #dfe2e6;
  box-shadow: inset 0 15px 15px -15px #dfe2e6;
  min-height: 60px;
  ${breakpoint("sm")`
      gap: 1rem;
      flex-direction: row;
  `}
  ${breakpoint("md")`
      flex-direction: row;
  `}
`;

export default SharedLayoutGLS;
