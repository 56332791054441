import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "../../components/form";
import FormAnnotation from "../../components/form-annotation";
import FormErrorMessage from "../../components/form-error-message";
import FormInput from "../../components/gls/form/FormInput";
import FormButton from "../../components/gls/form/FormButton";
import FormInputGroup from "../../components/gls/form/FormInputGroup";
import FormNotice from "../../components/form-notice/FormNotice";
import FormSelect from "../../components/gls/form/FormSelect";
import FormOption from "../../components/form-option/FormOption";
import FormPanel from "../../container/form-panel";
import {
  loadTherapeuticAreas,
  registerUser,
  resetRegistration,
} from "../../store/user/actions";
import {
  loadUserCompanies,
  loadUserFunctions,
  loadUserLanguages,
} from "../../store/user/actions/UserActions";
import { COUNTRIES } from "../../utils/constants";
import { registerSchema } from "../../utils/form-schemata";
import { getCurrentTimeZone, getDefaultTimezone } from "../../utils/common";
import { getTimeZones } from "@vvo/tzdb";
import styled from "styled-components";
import Select from "react-select";
import { SlArrowDown } from "react-icons/sl";
import { trackEvent } from "../../store/tracking/actions";
import {
  TrackingEvent,
  TrackingObject,
  TrackingPageName,
} from "../../store/tracking/TrackingObject";
import { useAppConfig } from "../../contexts/app-config/app-config-context";
import { AreaSubcategoryFormGroup } from "../../components/area-subcategory/area-subcategory-form-input";

const TIMEZONES = getTimeZones().map((timezone) => {
  const { name, group } = timezone;
  return { value: name, label: name, group };
});

const currentTz = getCurrentTimeZone();
const defaultTimezone = getDefaultTimezone(TIMEZONES, currentTz);

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    success,
    error,
    companies,
    functions,
    therapeuticAreas,
    languages,
    loading,
  } = useSelector((state) => {
    const {
      user: {
        registration: { success, error, loading },
        companies,
        functions,
        languages,
        therapeuticAreas,
      },
    } = state;
    return {
      success,
      loading,
      error,
      companies,
      functions,
      therapeuticAreas,
      languages,
    };
  });

  useEffect(() => {
    dispatch(resetRegistration);
    dispatch(loadUserCompanies);
    dispatch(loadUserFunctions);
    dispatch(loadUserLanguages);
    dispatch(loadTherapeuticAreas);
    dispatch(
      trackEvent(
        TrackingObject(TrackingEvent.PAGE_VIEW, {
          pageName: TrackingPageName.REGISTRATION,
          platformArea: "system",
        })
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(
        trackEvent(
          TrackingObject(TrackingEvent.PAGE_VIEW, {
            pageName: TrackingPageName.REGISTRATION_CONFIRMATION,
          })
        )
      );
    }
  }, [success, dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues: {
      area_subcategories: [],
    },
  });

  const onSubmit = (data) => {
    // Transform email to lowercase before submitting
    const transformedData = {
      ...data,
      email: data.email.toLowerCase(),
    };

    dispatch(registerUser(transformedData));
  };

  const handleCancelClick = () => {
    navigate("..");
  };

  const { config } = useAppConfig();

  if (success) {
    return (
      <FormPanel>
        <Form>
          <FormNotice>
            <strong>THANK YOU</strong>
            <br />
            Please check our e-mail inbox and confirm your registration.
          </FormNotice>
          <FormButton
            onClick={handleCancelClick}
            title="GO BACK TO LOGIN"
            type="reset"
            primary
          />
        </Form>
      </FormPanel>
    );
  }
  const therapeuticId = watch("therapeutic_area");

  return (
    <FormPanel>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>REGISTRATION</h4>
        <p>Please fill in all the banks below to complete your registration.</p>
        <p>
          This is a generael registraion to the platform. Invitations for
          specific events will be sent out separately.
        </p>
        <p>You will receive an email confirming your registration afterwards</p>
        <FormHeading>Personal Data</FormHeading>
        <FormInputGroup>
          <FormInput
            type="text"
            {...register("firstname", { required: true })}
            placeholder="Firstname*"
            error={errors.firstname}
          />
          <FormErrorMessage message={errors.firstname} />
        </FormInputGroup>
        <FormInputGroup>
          <FormInput
            type="text"
            {...register("lastname", { required: true })}
            placeholder="Lastname*"
            error={errors.lastname}
          />
        </FormInputGroup>
        <FormErrorMessage message={errors.lastname} />
        <FormInputGroup>
          <FormInput
            type="text"
            {...register("email", { required: true })}
            placeholder="Email*"
            error={errors.email}
          />
          <FormErrorMessage message={errors.email} />
        </FormInputGroup>
        <FormInputGroup>
          <FormInput
            type="password"
            {...register("password", { required: true })}
            placeholder="Password*"
            error={errors.password}
          />
          <FormErrorMessage message={errors.password} />
        </FormInputGroup>
        <FormInputGroup>
          <FormInput
            type="password"
            {...register("confirm_password", { required: true })}
            placeholder="Confirm password*"
            error={errors.confirm_password}
          />
          <FormErrorMessage message={errors.confirm_password} />
        </FormInputGroup>
        <FormHeading>Organization</FormHeading>
        <FormSelect
          {...register("user_company", { required: true })}
          defaultValue=""
          error={errors.user_company}
        >
          <FormOption value="" disabled>
            Company*
          </FormOption>
          {companies.map((company) => {
            const { id, name } = company;
            return (
              <FormOption value={id} key={id}>
                {name}
              </FormOption>
            );
          })}
        </FormSelect>
        <FormErrorMessage message={errors.user_company} />
        <FormInputGroup>
          <FormInput
            type="text"
            {...register("job_title", { required: true })}
            placeholder="Job Title*"
            error={errors.job_title}
          />
          <FormErrorMessage message={errors.job_title} />
        </FormInputGroup>
        <FormSelect
          {...register("therapeutic_area", { required: true })}
          defaultValue=""
          error={errors.therapeutic_area}
        >
          <FormOption value="" disabled>
            {config.therapeutic_area_category_label}*
          </FormOption>
          {therapeuticAreas.map((therapeuticArea) => {
            const { id, title } = therapeuticArea;
            return (
              <FormOption value={id} key={id}>
                {title}
              </FormOption>
            );
          })}
        </FormSelect>
        <FormLabel>Area Sub-category*</FormLabel>
        {therapeuticId && (
          <AreaSubcategoryFormGroup
            layout="horizontal"
            {...register("area_subcategories", { required: true })}
            therapeuticId={therapeuticId}
          />
        )}
        {therapeuticId && (
          <FormErrorMessage message={errors.area_subcategories} />
        )}
        <FormErrorMessage message={errors.therapeutic_areas} />
        <FormSelect
          {...register("user_function", { required: true })}
          defaultValue=""
          error={errors.user_function}
        >
          <FormOption value="" disabled>
            Function*
          </FormOption>
          {functions.map((userFunction) => {
            const { id, title } = userFunction;
            return (
              <FormOption value={id} key={id}>
                {title}
              </FormOption>
            );
          })}
        </FormSelect>
        <FormErrorMessage message={errors.user_function} />
        <FormSelect
          {...register("country_code", { required: true })}
          defaultValue=""
          error={errors.country_code}
        >
          <FormOption value="" disabled>
            Country*
          </FormOption>
          {COUNTRIES.map((country) => {
            const { code, name } = country;
            return (
              <FormOption value={code} key={code}>
                {name}
              </FormOption>
            );
          })}
        </FormSelect>
        <FormErrorMessage message={errors.country_code} />
        <FormInputGroup>
          <FormInput
            type="text"
            {...register("region", { required: true })}
            placeholder="If you support a region please indicate"
            error={errors.region}
          />
          <FormErrorMessage message={errors.region} />
        </FormInputGroup>
        <FormHeading>Event Organization</FormHeading>
        <FormSelect
          {...register("language", { required: true })}
          defaultValue=""
          error={errors.language}
        >
          <FormOption value="" disabled>
            Language Preference*
          </FormOption>
          {languages.map((language) => {
            const { id, title } = language;
            return (
              <FormOption value={id} key={id}>
                {title}
              </FormOption>
            );
          })}
        </FormSelect>
        <FormErrorMessage message={errors.language} />
        <Controller
          name="timezone_offset"
          control={control}
          defaultValue={defaultTimezone.value}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              defaultValue={defaultTimezone}
              inputRef={ref}
              options={TIMEZONES}
              value={TIMEZONES.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
              components={{
                DropdownIndicator: () => (
                  <SlArrowDown
                    fontSize={9}
                    style={{
                      marginRight: 4,
                      color: "#727272",
                      fontWeight: "bold",
                    }}
                  />
                ),
              }}
              onBlur={onBlur}
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%",
                }),
                indicatorSeparator: () => null,
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #727272",
                  boxShadow: state.isFocused
                    ? "0 0 2px 2px rgba(0,155,119,0.5)"
                    : "none",
                  borderRadius: 0,
                  background: "transparent",
                  textAlign: "center",
                  minWidth: "100%",
                }),
              }}
            />
          )}
        />
        <FormErrorMessage message={errors.timezone_offset} />
        <FormErrorMessage message={error} />
        <FormAnnotation>* required fields</FormAnnotation>
        <FormButton loading={loading} type="submit" title="REGISTER" primary />
        <FormButton onClick={handleCancelClick} title="CANCEL" type="reset" />
        <PrivacyNotice>By submitting this registration form you confirm that you have read and agree to our <a href="/privacy-policy" target="_blank">Privacy Policy</a></PrivacyNotice>
    
      </Form>
    </FormPanel>
  );
};

const PrivacyNotice = styled.p`
  font-size: 0.875rem;

  a {
  text-decoration:underline;
  }
`

const FormHeading = styled.h5`
  margin-bottom: 0.5rem;
  background-color: #000;
  color: #fff;
  padding: 0.8rem 2rem;
  text-transform: uppercase;
  font-size: 1rem;
  align-self: flex-start;
`;

const FormLabel = styled.p`
  color: #727272;
  text-align: left;
  width: 100%;
`;

export default Register;
