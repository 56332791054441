import { Link } from "react-router-dom";
import "./glselence.style.css";

const GLSXCELLENCE = () => {
  return (
    <div class="page-content-outter">
      <div class="spacer item-4-by-1"/>
      <div class="page-content-inner">
        <div class="lobby-item item-1-by-1 lobby-bg-bluegreen">
          <div class="lobby-nav-title">
            GLS<strong>X</strong>CELLENCE
            <br /> <span>Spring Summit</span>
          </div>
        </div>
        <div class="content-wrapper">
          <img src="/images/GXC_0.png" class="mt-3 mb-3 img-fluid" />
          <div class="container-glsexcellence-wrapper">
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_1-0.png" class="img-fluid" />
              </Link>
            </div>
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_2-0.png" class="img-fluid" />
              </Link>
            </div>
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_3-0.png" class="img-fluid" />
              </Link>
            </div>
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_4-0.png" class="img-fluid" />
              </Link>
            </div>
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_5-0.png" class="img-fluid" />
              </Link>
            </div>
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_6-0.png" class="img-fluid" />
              </Link>
            </div>
            <div class="glsexcellence-item-outter">
              <Link to="#" class="glsexcellence-item">
                <img src="/images/GXC_7-0.png" class="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GLSXCELLENCE;
