import { useState } from "react";
import styled from "styled-components";
import "./LobbyGLS.css";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import MediaPlayer from "../../components/media-hub/media-player";
import { getMediaId } from "../../utils/media/media";

const GLS_TILE_TITLE = "GLS25 Live Highlights";
const GLS_TILE_URL =
  "https://playout.3qsdn.com/41017ab1-c090-45d2-b344-f252b9fb9c3a";

const LobbyGLS = () => {
  const [currentMedia, setCurrentMedia] = useState(null);
  return (
    <LobbyGridLayout>
      <LobbyItemGroup>
        <LobbyItem
          to="#"
          onClick={() =>
            setCurrentMedia({
              title: GLS_TILE_TITLE,
              url: GLS_TILE_URL,
              id: 1,
            })
          }
          className="lobby-item item-1-by-1 lobby-bg-bluegreen"
          id="lobby-item-3"
        >
          <img
            src="/images/gls/25/P Button 1.jpg"
            className="lobby-arrow hidden img-fluid first"
          />
          <img
            src="/images/gls/25/P Button 1 RO.jpg"
            className="lobby-arrow hidden img-fluid second"
          />
        </LobbyItem>
        <LobbyItem
          to="/gls/in-a-box"
          className="lobby-item item-1-by-1 lobby-bg-lightgreen"
          id="lobby-item-4"
        >
          <img
            src="/images/gls/25/P Button 2.jpg"
            className="lobby-arrow img-fluid first"
          />
          <img
            src="/images/gls/25/P Button 2 RO.jpg"
            className="lobby-arrow img-fluid second"
          />
          {/* <LinkArrow src="/images/gls/arrow.png" /> */}
        </LobbyItem>
        <LobbyItem
          to="/gls/mediahub"
          className="lobby-item item-1-by-1 lobby-bg-green"
          id="lobby-item-5"
        >
          <img
            src="/images/gls/25/P Button 3.jpg"
            className="lobby-arrow hidden img-fluid first"
          />
          <img
            src="/images/gls/25/P Button 3 RO.jpg"
            className="lobby-arrow hidden img-fluid second"
          />
          {/* <LinkArrow src="/images/gls/arrow.png" /> */}
        </LobbyItem>
        <LobbyItem
          to="/gls/dutube"
          className="lobby-item item-1-by-1 lobby-bg-green"
          id="lobby-item-6"
        >
          <img
            src="/images/gls/25/P Button 4.jpg"
            className="lobby-arrow hidden img-fluid first"
          />
          <img
            src="/images/gls/25/P Button 4 RO.jpg"
            className="lobby-arrow hidden img-fluid second"
          />
          {/* <LinkArrow src="/images/gls/arrow.png" /> */}
        </LobbyItem>
      </LobbyItemGroup>
      <Modal
        footer={null}
        isOpen={!!currentMedia?.id}
        title={currentMedia?.title}
        closeModal={() => setCurrentMedia(null)}
      >
        <MediaPlayer mediaId={getMediaId(currentMedia?.url)} />
      </Modal>
    </LobbyGridLayout>
  );
};

const LobbyGridLayout = styled.div`
  padding-top: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  width: 100vw;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    margin: 0rem;
    align-items: center;
  }
`;

const LobbyItemGroup = styled.div`
  display: flex;
  max-width: 100vw;
  justify-content: center;
  gap: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    align-items: center;
    margin: 0.25rem 0;
    text-align: center;
  }
`;

const LinkArrow = styled.img`
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%, -50%);
  width: .5rem;
`;

const LobbyItem = styled(Link)`
  position: relative;
  cursor: ${(props) => (props.to ? "cursor" : "default")};
  max-width: 200px;
  max-width: @media (max-width: 768px) {
    flex: 1;
  }
  :hover img.first {
    display: none;
  }
  :hover img.second {
    display: block;
  }
`;

export default LobbyGLS;
